var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.data && _vm.data.length > 0),expression:"data && data.length > 0"}],staticClass:"custom-table main",attrs:{"id":"cu-tab"},on:{"scroll":function (event) { return _vm.emitIfScrolledToEnd(event); }}},[_c('table',{staticClass:"p-shadow-2",attrs:{"id":"list-table-custom"}},[_c('thead',[_c('tr',{staticClass:"header"},_vm._l((_vm.columns),function(column,index){return _c('th',{key:index,class:column.class,style:(column.style)},[(!column.shortFieldName && column.header !== 'alert')?_c('span',[_vm._v(_vm._s(column.header))]):_vm._e(),(column.shortFieldName)?_c('span',{attrs:{"title":column.header}},[_vm._v(" "+_vm._s(column.header.slice(0, 1))+" ")]):_vm._e(),(column.info)?_c('i',{staticClass:"fa fa-info-circle",staticStyle:{"margin-left":"5px"},on:{"click":function (event) { return _vm.toggleInformation(event, column.infoContent); }}}):_vm._e(),(column.sort || column.naturalCompare)?_c('span',{on:{"click":function () { return _vm.emit('sort-on-column', column); }}},[(_vm.sortBy.find(function (ref) {
                    var sortField = ref.sortField;
                    var direction = ref.direction;

                    return column.sortField === sortField && direction === 'desc';
}))?_c('i',{class:['fas fa-sort-down', 'table-sort'],staticStyle:{"margin-left":"5px"}}):(_vm.sortBy.find(function (ref) {
                    var sortField = ref.sortField;
                    var direction = ref.direction;

                    return column.sortField === sortField && direction === 'asc';
}))?_c('i',{class:['fas fa-sort-up', 'table-sort'],staticStyle:{"margin-left":"5px"}}):_c('i',{class:['fas fa-sort', 'table-sort'],staticStyle:{"margin-left":"5px"}})]):_vm._e()])}),0)]),_c('tbody',_vm._l((_vm.data),function(row,index){return _c('tr',{key:index,ref:(_vm.activeRowNr === row.no) ? 'activeRowElement' : undefined,refInFor:true,class:{ opened: _vm.activeRowNr === row.no },attrs:{"id":'AA' + row.id},on:{"click":function () { return _vm.emit('row-click', row); }}},_vm._l((_vm.columns),function(column,index){return _c('td',{key:index,style:(column.bodyStyle)},[_c('div',[(_vm.isTextColumn(column) && row[column.fieldName] !== 0 && column.fieldName !== 'applicable')?_c('p',[(column.fieldName !== 'category')?_c('span',{domProps:{"innerHTML":_vm._s(row[column.fieldName] &&
                                _vm.removeMd(String(row[column.fieldName])))}}):_c('span',{domProps:{"innerHTML":_vm._s(String(row[column.fieldName]))}})]):_vm._e(),(row['inactive'] && column.fieldName === 'fullName')?_c('span',{staticClass:"inactive-user"},[_vm._v("("+_vm._s(_vm.t('INACTIVE', _vm.language.value))+")")]):_vm._e(),(column.pendingUser && !row['lastLogin'])?_c('div',[_c('Tag',{staticClass:"user-pending",attrs:{"value":"Pending","rounded":""}})],1):_vm._e(),(column.htmlTags)?_c('p',{domProps:{"innerHTML":_vm._s(row[column.fieldName])}}):_vm._e(),(column.type && !column.timestamp)?_c('p',[_vm._v(" "+_vm._s(row[column.fieldName] && _vm.moment(row[column.fieldName].split(' ')[0]).format('DD-MM-YYYY'))+" ")]):_vm._e(),(column.timestamp)?_c('p',[_vm._v(" "+_vm._s(row[column.fieldName] && _vm.moment(row[column.fieldName]).format('DD-MM-YYYY HH:mm:ss'))+" ")]):_vm._e(),(column.limit)?_c('p',{domProps:{"innerHTML":_vm._s(
                            _vm.utils.marked(
                                String(
                                    _vm.utils.limittedText(
                                        row[column.fieldName],
                                        column.limit
                                    )
                                )
                            )
                        )}}):_vm._e(),(column.shortFieldName)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.utils.marked(String(row[column.fieldName].slice(0, 1))))}}):_vm._e(),(column.level)?_c('div',[(_vm.data)?_c('LevelBar',{attrs:{"levelType":column.levelType,"score":row[column.fieldName]}}):_vm._e()],1):_vm._e(),(_vm.isEntityNumberColumn(column))?_c('div',[(column.alert)?_c('i',{staticClass:"fas fa-circle p-mr-2 alert-circle",style:({ color: row['color'] }),on:{"mouseover":function($event){_vm.toggleInformation($event, _vm.t(row['infoKey']))},"mouseleave":function($event){return _vm.infomationOp.toggle($event)}}}):_vm._e(),_c('span',{staticClass:"row-number"},[_vm._v(_vm._s(row[column.fieldName]))])]):_vm._e(),(column.colorBoolDot)?_c('div',[(row[column.fieldName] === true)?_c('div',{class:['p-mr-1']},[_c('i',{staticClass:"fas fa-thumbs-up"})]):(row[column.fieldName] === false)?_c('div',{class:['p-mr-1']},[_c('i',{staticClass:"fas fa-thumbs-down"})]):_c('div',[_vm._v(" "+_vm._s(row[column.fieldName])+" ")])]):_vm._e(),(column.fieldName == 'netRisk')?_c('div',[_c('p',{class:[row.netRiskStyleClass, 'circle']},[_vm._v(" "+_vm._s(row[column.fieldName])+" ")])]):_vm._e(),(column.effectiveness)?_c('div',[(row[column.fieldName] === _vm.Effectiveness.EFFECTIVE)?_c('i',{staticClass:"fas fa-check effective"}):_vm._e(),(row[column.fieldName] === _vm.Effectiveness.NOT_EFFECTIVE)?_c('i',{staticClass:"fas fa-times none-effective"}):_vm._e(),(row[column.fieldName] === _vm.Effectiveness.NOT_TESTED)?_c('span',[_vm._v(" - ")]):_vm._e()]):_vm._e(),(column.fieldName === 'applicable')?_c('div',[_vm._v(" "+_vm._s(_vm.t(row[column.fieldName] ? 'YES' : 'NO', _vm.language))+" ")]):_vm._e(),(column.list)?_c('ul',_vm._l((row[column.fieldName]),function(value,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(value)+" ")])}),0):_vm._e()]),(
                        !row[column.fieldName] &&
                        !column.colorBoolDot &&
                        row[column.fieldName] === 0
                    )?_c('div',[_vm._v(" - ")]):_vm._e()])}),0)}),0)]),_c('OverlayPanel',{ref:"infomationOp",staticClass:"information-overlay-panel",attrs:{"appendTo":"body","showCloseIcon":true,"id":"overlay_panel"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.informationContent)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }