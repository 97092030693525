

















































import { defineComponent, Ref, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '../mixins/helpers'
import CircleCmp from '@/shared/components/CircleCmp.vue'
import utils from '@/shared/mixins/utils'
import Confirmation from '@/shared/components/Confirmation.vue'
// http://vuejscomponent.com/package?name=remove-markdown
import removeMd from 'remove-markdown'
import moment from 'moment'
import TableFilter from '@/shared/components/TableFilter.vue'
import TableContents from '@/shared/components/table/TableContents.vue'
import { TableHeaderDTO } from '@/dto/tableHeaderDTO'

export default defineComponent({
    props: {
        columnHeaders: {
            type: null,
            required: true,
        },
        tableData: {
            type: null,
            required: true,
        },
        viewDetailsPopup: {
            type: Boolean,
        },
        nameOfEntity: {
            type: String,
        },
        heatmap: {
            type: Boolean,
            default: false,
        },
        loggedInUserFilter: {
            type: String,
        },
        searchValue: {},
        filterFields: {
            type: Array,
        },
        defaultFilters: {
            type: Object || null,
        },
        // totalPages of each entities based on perPage
        totalPages: {
            type: Number,
        },
        filterOptions: {
            type: Object,
        },
        columnIndicatorEnabled: {
            type: Boolean,
        },
        statusInOrder: {
            type: Array,
        },
    },

    components: {
        CircleCmp,
        Confirmation,
        TableFilter,
        TableContents,
    },

    setup(props, { emit }) {
        // Keep track of last sort direction for each column
        const sortDirections = ref({})

        // eslint-disable-next-line
        const copyOfTableData = ref([])
        // eslint-disable-next-line
        const activeRowNr = ref(null)
        const { t } = useI18n()
        const { language } = useState(['language'])
        const filterData = ref({})
        const viewFilterSection = ref(false)
        const filtersPerField: Ref<Map<string, Array<string>>> | Ref = ref(null)
        const pageNum = ref(1)
        const filterCount = ref(0)
        const sortedField = ref(null)
        const taskStatus: Ref = ref([])

        const searchCount = (count) => {
            emit('search-count', count)
        }

        // table search
        const tableSearch = (value) => {
            // reset filters when table search starts

            viewFilterSection.value = false
            // when search field is empty
            if (value === '') {
                copyOfTableData.value = props.tableData
                searchCount(copyOfTableData.value.length)
                return
            }

            // get all the field names from table columns
            const fields = props.columnHeaders.map((header) => header.fieldName)

            let filteredList = []
            // itterate fields to find matched values in tableData fields
            for (let i = 0; i < fields.length; i++) {
                filteredList = props.tableData.filter(
                    (val) =>
                        String(val[fields[i]]).toLowerCase().indexOf(value.toLowerCase()) !== -1
                )
                if (filteredList.length > 0) {
                    copyOfTableData.value = filteredList
                    searchCount(copyOfTableData.value.length)
                    return
                }
            }

            // when no match found
            if (filteredList.length === 0) {
                copyOfTableData.value = filteredList
                searchCount(copyOfTableData.value.length)
            }
        }

        // filter table data using key and set of values
        const filterTableData = (tableData, filters) => {
            const filterKeys = Object.keys(filters)
            return tableData.filter((eachObj) => {
                return filterKeys.every((eachKey) => {
                    if (!filters[eachKey].length) {
                        return true // passing an empty filter means that filter is ignored.
                    }
                    return filters[eachKey].includes(eachObj[eachKey])
                })
            })
        }

        // filter table by selected date range from filter selection
        const filterTableDataByDateRange = (tableData, dateRange, field) => {
            const resultProductData = tableData.filter((a) => {
                const date = new Date(a[field])
                return date >= dateRange.startDate && date <= dateRange.endDate
            })
            return resultProductData
        }

        const setTableData = () => {
            // set table data from props by taking a copy of props table data in order to update data with sort/filter
            copyOfTableData.value = props.tableData
            filtersPerField.value = new Map<string, Array<string>>()
        }
        // open filter overlay panel
        const toggleFilter = async (params) => {
            await setTableData()
            const formData = params.formData
            const date = params.date
            // let filteredListArr = props.tableData

            filterData.value = {}
            for (const key in formData) {
                filterData.value[key] = formData[key]
            }

            copyOfTableData.value = filterTableData(props.tableData, filterData.value)

            for (const key in date) {
                if (date[key]) {
                    copyOfTableData.value = filterTableDataByDateRange(
                        copyOfTableData.value,
                        date[key],
                        key
                    )
                }
            }
            
            // viewFilterSection.value = false
            // number of filtered records
            emit('update-record-count', copyOfTableData.value.length)
        }

        // reset filter fields
        const resetFilters = () => {
            copyOfTableData.value = props.tableData
            emit('update-record-count', copyOfTableData.value.length)
        }

        // set filter count
        const setCount = (count) => {
            sortedField.value = null
            filterCount.value = count
            emit('set-count', count)
        }

        const separateNullValues = (sortedData) => {
            const returnSeparated = sortedData.filter((val) => val.netRisk !== '-')
            return returnSeparated.concat(sortedData.filter((val) => val.netRisk === '-'))
        }

        // sort task status by ascending order of `taskStatus` (#809)
        const ascSortByTaskStatus = (header: string) => {
            copyOfTableData.value = copyOfTableData.value.sort((a, b) => {
                return taskStatus.value.indexOf(a[header]) - taskStatus.value.indexOf(b[header])
            })
        }

        // sort task status by descending order of `taskStatus` (#809)
        const dscSortByTaskStatus = (header: string) => {
            copyOfTableData.value = copyOfTableData.value.sort((a, b) => {
                return taskStatus.value.indexOf(b[header]) - taskStatus.value.indexOf(a[header])
            })
        }

        // Compare two records on the specified field, removing markdown if necessary
        const compareRecordsOnField = (fieldName: string) => 
            (a: {[key: string]: unknown}, b: {[key: string]: unknown}) => {
            let removedMarkdownA
            let removedMarkdownB
            // removeMd apply only for strings without special characters as data
            if (
                typeof a[fieldName] === 'number' ||
                a[fieldName] === '-' ||
                typeof a[fieldName] === 'boolean'
            ) {
                removedMarkdownA = a[fieldName]
                removedMarkdownB = b[fieldName]
            } else {
                // remove markdown from text
                removedMarkdownA = removeMd(a[fieldName], {
                    // strip list leaders (default: true)
                    stripListLeaders: true,
                    // char to insert instead of stripped list leaders (default: '')
                    listUnicodeChar: '',
                    // support GitHub-Flavored Markdown (default: true)
                    gfm: true,
                    // replace images with alt-text, if present (default: true)
                    useImgAltText: true,
                })
                removedMarkdownB = removeMd(b[fieldName], {
                    stripListLeaders: true,
                    listUnicodeChar: '',
                    gfm: true,
                    useImgAltText: true,
                })
            }

            if (removedMarkdownA == null || removedMarkdownA === '-') {
                return 0
            }
            return removedMarkdownA > removedMarkdownB ? 1 : -1
        }

        // ascending sort
        const ascendingSort = (header: string) => {
            let sortedData = []

            sortedData = copyOfTableData.value.slice().sort(
                (a, b) => compareRecordsOnField(header) (a, b)
            )
            // separate '-' from table when sort netrisk
            if (header === 'netRisk') {
                copyOfTableData.value = separateNullValues(sortedData)
            } else {
                copyOfTableData.value = sortedData
            }
        }
        // decsending sort
        const descendingSort = (header: string) => {
            const sortedData = copyOfTableData.value.slice().sort(
                (a, b) => compareRecordsOnField(header) (b, a)
            )

            // separate '-' from table when sort netrisk
            if (header === 'netRisk') {
                copyOfTableData.value = separateNullValues(sortedData)
            } else {
                copyOfTableData.value = sortedData
            }
        }

        // column sort for strings with numeric numbers
        const naturalCompareByColumn = (header: string, direction: 'asc' | 'desc') => {
            if (direction === 'asc') {
                copyOfTableData.value = utils.naturalCompare(copyOfTableData.value, header)
            } else {
                copyOfTableData.value = utils
                    .naturalCompare(copyOfTableData.value, header)
                    .reverse()
            }
        }

        const sortOnColumn = (column: TableHeaderDTO) => {
            console.log(`Sorting on column ${column.fieldName}`)
            const flipDirection = (direction: 'asc' | 'desc' | undefined) => 
                direction === 'desc' ? 'asc' : 'desc'

            // Flip the direction, or set to ascending if it was not set
            const direction = flipDirection(
                sortDirections.value?.[column.fieldName]
            )

            sortDirections.value[column.fieldName] = direction

            // Determine the required type of sort based on the column metadata
            if (column.naturalCompare) {
                naturalCompareByColumn(column.fieldName, direction)
            }
            else if (column.fieldName === 'taskStatus') {
                // Use specialized sorting strategy for task status
                direction === 'asc'
                    ? ascSortByTaskStatus(column.fieldName)
                    : dscSortByTaskStatus(column.fieldName)
            }
            else {
                // Use default sorting strategy
                direction === 'asc' 
                    ? ascendingSort(column.fieldName)
                    : descendingSort(column.fieldName)
            }
        }


        // This function is called from parent components!
        const activeRow = (rowNr) => activeRowNr.value = rowNr

        const openViewDetailsPopup = (rowNr: number) => {
            activeRowNr.value = rowNr
            const rowId = copyOfTableData.value.find((val) => val.no === rowNr).id
            emit('open-details-popup', rowId)
        }

        // display no data found
        const setNoDataFound = async () => {
            await setTableData()
            copyOfTableData.value = []
        }

        // enable filter section
        const enableFilter = () => {
            viewFilterSection.value = !viewFilterSection.value
        }

        // set table height for frontend active filter
        const setTableHeight = async () => {
            // enabling filter section should stay in a separate function as cannot use await there directly
            await enableFilter()

            // get filter-section element.
            const filterSectionEle: HTMLElement = document.querySelector('.filter-section')

            // get custom table component
            const node: HTMLElement = document.querySelector('.custom-table')
            // get the height of filter section
            if (filterSectionEle) {
                const height = filterSectionEle.offsetHeight
                // 140px is a constant height of top section
                const uppersectionHeight = height + 140
                // if filter section is opened, set height manually
                if (viewFilterSection.value && node) {
                    node.style.height = 'calc(100vh - ' + String(uppersectionHeight) + 'px)'
                } else {
                    // when the filter section is closed, set default height
                    if (node) node.style.height = '86vh'
                }
            } else {
                // when the filter section is closed, set default height
                if (node) node.style.height = '86vh'
            }
        }

        const loadMoreIfRequired = () => {
            if (props.totalPages > pageNum.value) {
                pageNum.value = pageNum.value + 1
                emit('loadRecords', { pageNum: pageNum.value })
            }
        }

        // scroll cloumns to left
        const colScrollToLeft = () => {
            /* Introduce two elements to differentiate table elements when there are more than one in the sidepanel */
            const element = document.querySelector('.tab-list-table .custom-table')
            const elementTwo = document.querySelector('.tab-list-table-2 .custom-table')
            const elementThree = document.querySelector('.tab-list-table-3 .custom-table')
            if (elementTwo) elementTwo.scrollLeft -= 150
            if (element) element.scrollLeft -= 150
            if (elementThree) elementThree.scrollLeft -= 150
        }

        // scroll columns to right
        const colScrollToRight = () => {
            /* Introduce two elements to differentiate table elements when there are more than one in the sidepanel */
            const element = document.querySelector('.tab-list-table .custom-table')
            const elementTwo = document.querySelector('.tab-list-table-2 .custom-table')
            const elementThree = document.querySelector('.tab-list-table-3 .custom-table')
            if (elementTwo) elementTwo.scrollLeft += 150
            if (element) element.scrollLeft += 150
            if (elementThree) elementThree.scrollLeft += 150
        }

        //  watch for table data changes
        watch(
            () => props.tableData,
            () => {
                setTableData()
            },
            { immediate: true }
        )

        // watch for status order values
        watch(
            () => props.statusInOrder,
            () => {
                if (props.statusInOrder) {
                    taskStatus.value = props.statusInOrder
                }
            },
            {
                immediate: true,
            }
        )

        // watch for search value and call tableSearch
        watch(
            () => props.searchValue,
            () => {
                tableSearch(props.searchValue)
            }
        )

        // watch for defaultFilters from entities and display filter section accordingly
        watch(
            () => filterCount.value,
            () => {
                if (filterCount.value > 0) {
                    viewFilterSection.value = true
                }
            },
            { immediate: true }
        )

        return {
            copyOfTableData,
            activeRowNr,
            activeRow,
            t,
            language,
            toggleFilter,
            utils,
            separateNullValues,
            moment,
            openViewDetailsPopup,
            removeMd,
            resetFilters,
            setCount,
            viewFilterSection,
            searchCount,
            setNoDataFound,
            setTableHeight,
            colScrollToLeft,
            colScrollToRight,
            ascSortByTaskStatus,
            dscSortByTaskStatus,
            sortOnColumn,
            loadMoreIfRequired,
        }
    },
})
