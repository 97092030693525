






















































































































































































import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import moment from 'moment'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '../mixins/helpers'
import utils from '@/shared/mixins/utils'
import { UserRole } from '../enum/general-enum'

export default defineComponent({
    props: {
        filterFields: {
            type: Array,
        },
        copyOfTableData: {
            type: Array,
        },
        loggedInUserFilter: {},
        nameOfEntity: {},
        defaultFilters: {
            type: Object || null,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const headerswithFilters = ref([])
        const formData = ref({})
        const dateFilters = ref([])
        const selectedDatefilter = ref({})
        const selectedDates = ref({})
        const displayCalenderFilter = ref(false)
        const CUSTOM_DATE_RANGE = ref({
            startDate: new Date(),
            endDate: new Date(),
        })
        const filterList = ref({})
        const selectedCustomDateField = ref('')
        const count = ref(0)
        const selectedItems = ref([])
        const filterfieldLoggedInUser = ref('')
        const selectedFilters = ref([])
        const { role } = useState(['role'])
        // convert net risk sore class names to text
        const convertNetRiskClassToText = (className) => {
            switch (className) {
                case 'net-risk-red':
                    return t('RED', language.value)
                case 'net-risk-orange':
                    return t('ORANGE', language.value)
                case 'net-risk-green':
                    return t('GREEN', language.value)
                case 'no-color':
                    return '-'
            }
        }

        // set filter data list as per field
        const setFilterData = (field, translatable) => {
            const filterListTemp = ref([])
            let tempMappedData = []

            // object array with netRiskStyleClass and netRiskColor(converted) for net risk
            if (field === 'netRisk') {
                props.copyOfTableData.map((item) => {
                    const temp = {
                        netRiskclass: '',
                        netRiskColor: null,
                    }
                    temp.netRiskclass = item['netRiskStyleClass']
                    temp.netRiskColor = convertNetRiskClassToText(item['netRiskStyleClass'])
                    tempMappedData.push(temp)
                })
            } else {
                // object array for other filters
                tempMappedData = props.copyOfTableData.map((item) => {
                    return {
                        key: item[field],
                        value: translatable ? t(item[field], language.value) : item[field],
                    }
                })
                if (props.loggedInUserFilter && field === filterfieldLoggedInUser.value) {
                    // If this is the filter field that is used for the logged in user, we want to add
                    // the user to the filter choices, even if it does not occur anywhere in the data
                    tempMappedData.push({
                        key: props.loggedInUserFilter,
                        value: props.loggedInUserFilter
                    })
                }
            }

            // since netRisk is an object array, remove duplicates
            if (field === 'netRisk') {
                filterListTemp.value = tempMappedData.filter(
                    (v, i, a) => a.findIndex((t) => t.netRiskColor === v.netRiskColor) === i
                )
                filterList.value['netRisk'] = filterListTemp.value
            } else {
                // remove object array duplicates
                filterListTemp.value = tempMappedData.filter(
                    (tag, index, array) => array.findIndex((t) => t.key == tag.key) == index
                )

                filterList.value[field] = filterListTemp.value
                // find if 'NO_OWNER' value exist in array (the way to find owner field with NO_OWNER)
                const findNoOwnerExist = filterList.value[field].map(
                    (val) => val.value === t('NO_OWNER'),
                    language.value
                )
                // numeric sort
                filterList.value[field].sort((a, b) => (a.value > b.value ? 1 : -1))
                // for string + numeric sort
                filterList.value[field] = utils.naturalCompare(filterList.value[field], 'value')

                if (findNoOwnerExist.length > 0) {
                    // display No_OWNER value in last of the array
                    utils.updateElementPositionOfArray(
                        filterList.value[field],
                        t('NO_OWNER', language.value),
                        filterList.value[field].length - 1
                    )
                }
            }
        }
        // get headers with filters true
        const getHeaderswithFilters = () => {
            headerswithFilters.value = props.filterFields
            headerswithFilters.value.map((val) => {
                // set dropdown filter options
                setFilterData(val.fieldName, val.translatable)
            })
            // set date filter values. todo : enable translate
            dateFilters.value = [
                { name: 'Select', value: 'select' },
                { name: '1 month', value: 'oneMonth' },
                { name: '3 months', value: 'threeMonth' },
                { name: '1 year', value: 'oneYear' },
                { name: 'custom date', value: 'customDate' },
            ]
        }

        // trigger toggleFilter method in Table component
        const applyFilter = () => {
            emit('toggle-filter', { formData: formData.value, date: selectedDates.value })
        }

        //  convert selected date filter to date range
        const convertToDateRange = (selectedItem) => {
            const today = new Date()
            let dateRange
            switch (selectedItem) {
                case 'oneMonth':
                    {
                        dateRange = {
                            startDate: new Date(String(moment(today).subtract(1, 'M'))),
                            endDate: new Date(String(moment(today))),
                        }
                    }
                    break
                case 'threeMonth':
                    {
                        dateRange = {
                            startDate: new Date(String(moment(today).subtract(3, 'M'))),
                            endDate: new Date(String(moment(today))),
                        }
                    }
                    break
                case 'oneYear':
                    {
                        dateRange = {
                            startDate: new Date(String(moment(today).subtract(1, 'y'))),
                            endDate: new Date(String(moment(today))),
                        }
                    }
                    break
                case 'customDate':
                    {
                        dateRange = {
                            startDate: new Date(String(moment(CUSTOM_DATE_RANGE.value.startDate))),
                            endDate: new Date(String(moment(CUSTOM_DATE_RANGE.value.endDate))),
                        }
                    }
                    break
            }

            return dateRange
        }

        // update selectedDates with selected date type(range)
        const setDataWithDate = (type, fieldName, val) => {
            if (val[fieldName]['value'] === 'customDate') {
                selectedCustomDateField.value = fieldName
                displayCalenderFilter.value = true
            }
            if (val[fieldName]['value'] === 'select') {
                selectedDates.value[fieldName] = null
                return
            }
            // convert selected date type into date range
            const convertedDate = convertToDateRange(val[fieldName]['value'])
            selectedDates.value[fieldName] = convertedDate
        }

        // set selected custom date range to selectedDates
        const setSelectedDateRange = () => {
            const convertedDate = convertToDateRange('customDate')
            selectedDates.value[selectedCustomDateField.value] = convertedDate
            displayCalenderFilter.value = false

            applyFilter()
        }

        // reset filters
        const resetFilters = () => {
            formData.value = {}
            selectedDates.value = {}
            selectedCustomDateField.value = ''
            selectedItems.value = []
            count.value = 0
            selectedDatefilter.value = {}
            selectedFilters.value = []
            emit('set-count', count.value)
            applyFilter()
        }

        /* when by default filters are active there could be filter values which don't available in filter list options.
        Hence to avoid display them as null, removing them from formData.value. */
        const removeNullValues = () => {
            for (const key in formData.value) {
                // Since `filterList` contain an object array(key,value) get an array with keys to compare with `formData` values
                if (filterList.value[key]) {
                    const filterListKeys = filterList.value[key].map((val) => val.key)
                    if (formData.value[key].length > 0)
                        // keep only mutual values from `formData` and `filterListKeys`
                        formData.value[key] = formData.value[key].filter((x) =>
                            filterListKeys.includes(x)
                        )
                }
            }
        }

        // set the count to number of selected items
        const setFilterCount = () => {
            // store selected items in chips (selectedFilters)
            selectedFilters.value = []
            count.value = 0
            // for other types
            for (const key in formData.value) {
                if (formData.value[key]) {
                    // store selected items in selectedFilters to display in chips
                    for (let i = 0; i < formData.value[key].length; i++) {
                        selectedFilters.value.push({ field: key, value: formData.value[key][i] })
                    }

                    count.value = formData.value[key].length + count.value
                }
            }
            // for single dropdown (date)
            for (const key in selectedDates.value) {
                if (selectedDates.value[key]) {
                    count.value = count.value + 1
                }
            }

            // for single dropdown (date) without convert dates (for chips)
            for (const key in selectedDatefilter.value) {
                if (
                    selectedDatefilter.value[key] &&
                    selectedDatefilter.value[key].value !== 'select'
                ) {
                    const type = selectedDatefilter.value[key].value

                    selectedFilters.value.push({
                        field: key,
                        value: selectedDatefilter.value[key].name,
                        type,
                    })
                }
            }

            applyFilter()

            /* This must call after setting `selectedFilters` values, to display all the requested filter values
            before remove unavailable values in filter list option */
            removeNullValues()

            emit('set-count', count.value)
        }

        // remove selected chip item
        const removeSelectedItem = (field: string, value: string | number) => {
            const indexOfFormData = formData.value[field] && formData.value[field].indexOf(value)

            if (indexOfFormData > -1) {
                // remove selected items from `formData.value`
                formData.value[field].splice(indexOfFormData, 1)
                // remove  selected filter items from localstorage
                localStorage.removeItem(props.nameOfEntity + field)
            }
            /* workaround to remove formData.value cache when user navigate from dashboard
            // TODO: find the difference when manually adding values to formData and fix the issue. */
            if (
                indexOfFormData === 0 &&
                (field === filterfieldLoggedInUser.value ||
                    field === 'status' ||
                    field === 'norm' ||
                    field === 'netChance' ||
                    field === 'netImpact' ||
                    field === 'category' ||
                    field === 'alertColorName' ||
                    field === 'friskType' || 
                    field === 'isLatest')
            ) {
                if (formData.value[field].length == 0) {
                    formData.value[field] = ''
                }
            }

            // for date (single dropdown)
            if (selectedDatefilter.value[field]) {
                // remove element from both covreted date object and dropdown(date) v-model

                delete selectedDatefilter.value[field]
                delete selectedDates.value[field]
            }

            setFilterCount()
        }

        // set default filters for logged in user(validator) when navigate from dashboard
        const setDefaultFilterForValidator = () => {
            // display other executors records except logged in users
            const findLoggedInUserRecord = filterList.value[filterfieldLoggedInUser.value].filter(
                (val) => val.value !== props.loggedInUserFilter
            )
            // set `findLoggedInUserRecord` array with keys as `formData` is having arrays with keys
            formData.value[filterfieldLoggedInUser.value] = findLoggedInUserRecord.map(
                (val) => val.key
            )
            setFilterCount()
        }

        // when user navigating from dashboard set filter values by default
        const setByDefaultFilters = (entity) => {
            // if norms are selected
            if (entity === 'control') {
                return
                // when user navigating from dashboard
            }

            // if loggedin user is found (navigating from dashboard), apply filter
            if (props.loggedInUserFilter) {
                switch (entity) {
                    case 'risks':
                        filterfieldLoggedInUser.value = 'ownerUserName'
                        break
                    case 'control':
                        filterfieldLoggedInUser.value = 'fowner'
                        break
                    case 'actionplan':
                        filterfieldLoggedInUser.value = 'fowner'
                        break
                    case 'riskassessment':
                        filterfieldLoggedInUser.value = 'fexecutedBy'
                        break
                    case 'controlassessment':
                        filterfieldLoggedInUser.value = 'fexecutedBy'
                        break
                    default:
                        'No entity found'
                }
                // set default filters when navigating from dashboard
                if (role.value === UserRole.VALIDATOR) {
                    setDefaultFilterForValidator()
                } else {
                    // setDefaultFilterForLoggedInUser()
                }
            } //when user access from menu, remove existing loggedinUser default filters
            else {
                delete formData.value[filterfieldLoggedInUser.value]
                setFilterCount()
            }
        }

        // set default filters for lists
        const setDefaultFilters = () => {
            const defaultFilters = props.defaultFilters
            // set `formData` values(default filters)
            Object.keys(defaultFilters).forEach((key) => {
                if (key) formData.value[key] = defaultFilters[key]
                // This function was also sorting the table contents on alertColorName
                // I removed that sort because it is confusing that the filter component
                // is also changing the order of the data.
            })
        }

        // set filter values which saved in local storage
        const setLocalStorageFilters = () => {
            headerswithFilters.value.map((val) => {
                let savedFilters: string
                // get filter values for each fields(`props.nameOfEntity + val.fieldName` will be the unique name to save in localStorage)
                /* since `netRisk` has been converted to display in text colors in filter section,
                filter option name is `netRiskStyleClass` for this case */
                if (val.fieldName === 'netRisk') {
                    savedFilters = utils.ls.get(props.nameOfEntity + 'netRiskStyleClass', {
                        decrypt: true,
                    })
                } else {
                    // get filter values for each fields
                    savedFilters = utils.ls.get(props.nameOfEntity + val.fieldName, {
                        decrypt: true,
                    })
                }

                // if there are stored values for the fields set them to `formData.value` and apply filter(`setFilterCount()`)
                if (savedFilters && val.fieldName === 'netRisk') {
                    formData.value['netRiskStyleClass'] = JSON.parse(savedFilters)
                } else if (savedFilters) {
                    formData.value[val.fieldName] = JSON.parse(savedFilters)
                }
            })
        }

        // apply filter manually and save only manually selected filters on local storage(#820)
        const setFilterManually = () => {
            for (const key in formData.value) {
                if (formData.value[key]) {
                    utils.ls.set(props.nameOfEntity + key, JSON.stringify(formData.value[key]))
                }
            }
            setFilterCount()
        }

        watch(
            () => props.defaultFilters,
            async () => {
                if (
                    props.filterFields &&
                    props.filterFields.length > 0 &&
                    props.nameOfEntity !== 'control'
                ) {
                    await getHeaderswithFilters()
                    setLocalStorageFilters()
                }
                if (
                    props.defaultFilters &&
                    Object.keys(props.defaultFilters).length > 0 &&
                    props.nameOfEntity !== 'control'
                ) {
                    setDefaultFilters()
                }
                setFilterCount()
            },
            { immediate: true }
        )

        // watch table data update and set filter configurations
        watch(
            () => props.copyOfTableData,
            async () => {
                // when table data gets update, update filter options accordingly and set localStoage values
                if (
                    props.filterFields &&
                    props.filterFields.length > 0 &&
                    props.nameOfEntity !== 'control'
                ) {
                    await getHeaderswithFilters()
                    setLocalStorageFilters()
                }
                setByDefaultFilters(props.nameOfEntity)
            }
        )

        onMounted(async () => {
            setByDefaultFilters(props.nameOfEntity)
        })
        return {
            t,
            language,
            headerswithFilters,
            formData,
            applyFilter,
            dateFilters,
            selectedDatefilter,
            displayCalenderFilter,
            setDataWithDate,
            CUSTOM_DATE_RANGE,
            setSelectedDateRange,
            selectedCustomDateField,
            convertToDateRange,
            convertNetRiskClassToText,
            filterList,
            resetFilters,
            selectedDates,
            setFilterCount,
            moment,
            utils,
            selectedFilters,
            removeSelectedItem,
            setFilterManually,
        }
    },
})
