






















import { defineComponent, PropType, computed, watch, ref, onMounted } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import threatData from '@/assets/data/threats-data.json'
import vulnerabilityData from '@/assets/data/vulnerability-data.json'
import utils from '@/shared/mixins/utils'

export default defineComponent({
    name: 'LevelBar',
    props: {
        score: { // The score of the level (in range 1 to 5)
            type: Number,
            required: true,
        },
        levelType: { // Entity type, used to get the right entity explanation
            type: String as PropType<'threatLevel' | 'vulnerabilityLevel' | string>,
            required: true,
        },
        showExplanation: {
            type: Boolean,
            default: true
        }
    },
    setup(props) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const progressWrapper = ref(null)
        // these classes contains different color gradients based on level values
        const levelClasses = [
            'level-one-color',
            'level-two-color',
            'level-three-color',
            'level-four-color',
            'level-five-color',
        ]

        const levelClass = computed(
            // Get the matching class for the list, or fall back to the highest one
            () => levelClasses?.[props.score - 1] ?? levelClasses.slice(-1)[0]
        )

        // Add a class that overrides the PrimeVue progress bar with a color gradient
        // matching the level to which it is filled
        const setGradientClass = (newLevelClass: string) => {
            const el = progressWrapper.value?.querySelector('.p-progressbar-value')
            if (el) {
                // Remove any level classes that may be set
                el.classList.remove(...levelClasses)
                // And set the newe class
                el.classList.add(newLevelClass)
            }
        }

        
        // We cannot just use an immediate watcher because the DOM must be avaialable
        onMounted(() => setGradientClass(levelClass.value))
        
        // When the score changes, update the gradient class
        watch(
            levelClass,
            (newLevelClass) => setGradientClass(newLevelClass)
        )

        // The explanation depends on the entity type 
        const levelExplanationKey = computed(() => {
            switch (props.levelType) {
                case "threatLevel":
                    return threatData.THREAT_LEVELS.find(
                        (val) => val.SCORE === props.score
                    )?.VALUE ?? ""
                case "vulnerabilityLevel": 
                    return vulnerabilityData.VULNERABILITY_LEVELS.find(
                        (val) => val.SCORE === props.score
                    )?.VALUE ?? ""
                default: 
                    return "" // This translation key does not exist, so the explanation text will be empty
            }
        })

        const levelPercentage = computed(
            () => utils.converLevelsToPercentage(props.score)
        )

        return {
            t,
            language,
            progressWrapper,
            levelPercentage,
            levelClass,
            levelExplanationKey,
        }
    },
})
