












import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import controlAssessmentDropdownData from '@/assets/data/control-assessment-data.json'

export default defineComponent({
    name: 'CircleCmp',
    props: {
        levelValue: {
            type: Number,
            required: true,
        },
        nameOfEntity: {
            type: String,
        },
    },
    setup(props) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const circleLevels = ref([])

        const controlCircles = (levelData) => {
            circleLevels.value = []
            const leveltrans = levelData.find((val) => val.SCORE === props.levelValue)?.VALUE
            for (let i = 1; i <= 5; i++) {
                if (i <= props.levelValue) {
                    circleLevels.value.push({
                        className: 'colored',

                        explanation: leveltrans,
                    })
                } else {
                    circleLevels.value.push({
                        className: 'non-colored',
                        explanation: leveltrans,
                    })
                }
            }
        }

        const convertLevelValuesToCircles = () => {
            /* circleLevels contains threat/vulnerability level information such as threat/vulnerability score explanation(explanation) and 
                 pushing 'colored' classes according to threat/vulnerability level value. 'colored' class will fill circles with green color and 
                'explanation' is required to display explanation on mouse hover circles */

            let levelData = []

            levelData = controlAssessmentDropdownData.MATURITY
            controlCircles(levelData)
        }

        onMounted(() => {
            convertLevelValuesToCircles()
        })

        //  watch for level score change and update the circle array accordingly
        watch(
            () => props.levelValue,
            () => {
                convertLevelValuesToCircles()
            }
        )

        return {
            t,
            language,
            circleLevels,
        }
    },
})
