

















































































































































































import { computed, defineComponent, PropType, ref, Ref, watch } from '@vue/composition-api';
import { Effectiveness } from '@/shared/enum/asset-control-assessment-enum'
import moment from 'moment';
import utils from '@/shared/mixins/utils';
import { useI18n } from 'vue-i18n-composable';
import { useState } from '@/shared/mixins/helpers';
import type { TranslateResult } from 'vue-i18n'
import { TableHeaderDTO } from '@/dto/tableHeaderDTO';
import removeMd from 'remove-markdown'
import LevelBar from '@/shared/components/LevelBar.vue'
import { SortOrderDTO } from '@/dto/sortOrderDTO';
import router from '@/router';

interface TableEntry {
    netRiskStyleClass?: string;
    no: number;
    id: number;
}

export default defineComponent({
    props: {
        columns: {
            required: true,
            type: Array as PropType<Array<TableHeaderDTO>>
        },
        data: {
            required: true,
            type: Array as PropType<Array<TableEntry>>,
        },
        activeRowNr: {
        },
        sortBy: {
            type: Array as PropType<SortOrderDTO>,
            default: () => [],
        },
        scrollToEndBuffer: {
            /**
             * This component will emit an event of type scrolled-to-end when the mumber of remaining
             * scrollable pixels get below this value. 
             */
            type: Number,
            default: 50,
        }
    },
    components: {
        LevelBar,
    },
    setup(props, { emit }) {
        const { language } = useState(['language'])
        const { t } = useI18n()
        const isPageControls = computed(() => router.currentRoute.name.toLowerCase() === 'controls');

        const infomationOp = ref(null)
        const informationContent: Ref<TranslateResult> = ref('')

        const activeRowElement: Ref<Array<Element>> = ref(null);
        // open information overlay panel for alert
        const toggleInformation = (event: object, info: string) => {
            informationContent.value = info

            infomationOp.value.toggle(event)
        }

        // display as a standard text record if following table header properties are not applied
        const isTextColumn = (header: TableHeaderDTO) => {
            return (
                !header.level &&
                !header.limit &&
                !header.alert &&
                !header.shortFieldName &&
                header.fieldName !== 'netRisk' &&
                !header.colorBoolDot &&
                !header.type &&
                header.fieldName !== 'no' &&
                header.fieldName !== 'refId' &&
                !(header.fieldName === 'controlNumber' || header.fieldName === 'msrNumber') &&
                !header.htmlTags &&
                !header.effectiveness &&
                !header.list
            )
        }

        const isEntityNumberColumn = (header: TableHeaderDTO) => {
            return (
                header.fieldName === 'refId' ||
                header.fieldName === 'no' ||
                header.fieldName === 'controlNumber' ||
                header.fieldName === 'msrNumber'
            )
        }

        const emitIfScrolledToEnd = utils.debounce(
            (event: Event) => {
                if (event.target instanceof Element) {
                    const pixelsLeft = Math.abs(event.target.scrollHeight - event.target.clientHeight - event.target.scrollTop);
                    if (pixelsLeft < props.scrollToEndBuffer) {
                        emit('scrolled-to-end');
                    }
                }
            },
            200, 
            null
        )

        watch(
            () => activeRowElement.value,
            (activeRowElements: Array<Element>) => {
                activeRowElements?.[0].scrollIntoView({behavior: 'smooth', block: 'center'})
            }
        )

        return {
            emit,
            t,
            language,
            Effectiveness,
            moment,
            utils,
            toggleInformation,
            infomationOp,
            informationContent,
            isTextColumn,
            isEntityNumberColumn,
            emitIfScrolledToEnd,
            removeMd,
            activeRowElement,
            isPageControls
        }
    }
})
